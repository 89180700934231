// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "components/Layouts/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "components/FooterPages";
import DataTable from "components/Tables/DataTable";

// Data
import clientsData from "./data/tableData";
import MDButton from "components/MDButton";

function ListarClientes() {
  const navigate = useNavigate();
  const { rows } = clientsData();

  const columns = [
    { Header: "cnpj", accessor: "author", align: "left" },
    { Header: "razão social", accessor: "function",width: "45%", align: "left" },
    { Header: "status", accessor: "status", width: "10%", align: "left" },
    { Header: "acesso assitência", accessor: "contract", width: "40%",align: "center" },
    { Header: "Ações", accessor: "action", align: "center" },
  ]

  const onClickNew = () =>{
    navigate('/cadastroDistribuidora');
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div style={{
        display: 'flex',
        justifyContent: 'flex-end'
      }}>
        <MDBox mt={0} mb={1} >
          <MDButton variant="gradient" color="info" type="button" onClick={onClickNew}>
            Cadastrar nova
          </MDButton>
        </MDBox>
      </div>
      <MDBox pt={3} pb={3} height="78vh">
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Distribuidoras
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted
                  entriesPerPage
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ListarClientes;
