const uniques = (array = [], fieldKeyOrFieldFunction) => {
  const current = {};
  const items = [];
  const comparator = value => typeof fieldKeyOrFieldFunction === 'function'
    ? fieldKeyOrFieldFunction(value)
    : value[fieldKeyOrFieldFunction];
  array.forEach(value => {
    const key = comparator(value);
    if ( !current[key] ) {
      current[key] = true;
      items.push(value);
    }
  });
  return items;
}

export const ArrayHelper = {
  uniques,
}