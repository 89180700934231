// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DataTable from "components/Tables/DataTable";

// Data
import clientsData from "./data/tableData";
import MDButton from "components/MDButton";

function ListarPedidos() {
  const navigate = useNavigate();
  const { rows } = clientsData();

  const columns = [
    { Header: "Nome", accessor: "razao",width: "45%", align: "left" },
    { Header: "Telefone", accessor: "telefone", align: "left" },
    { Header: "Status", accessor: "status", width: "10%", align: "left" },
    { Header: "Nº Telas", accessor: "telas", width: "40%",align: "center" },
    { Header: "Ações", accessor: "action", align: "center" },
  ]

  const onClickNew = () =>{
    navigate('/cadastroPedidos');
  }

  return (<>
    <div style={{
      display: 'flex',
      justifyContent: 'flex-end'
    }}>
      <MDBox mt={0} mb={1} >
        <MDButton variant="gradient" color="info" type="button" onClick={onClickNew}>
          Cadastrar novo
        </MDButton>
      </MDBox>
    </div>
    <MDBox pt={3} pb={3} height="78vh">
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Registro de telas
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <DataTable
                table={{ columns, rows }}
                isSorted
                entriesPerPage
                showTotalEntries={false}
                noEndBorder
              />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  </>);
}

export default ListarPedidos;
