import * as XLSX from 'xlsx';


const exportToCsv = (jsonData) => {
  try {
    const worksheet = XLSX.utils.json_to_sheet(jsonData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

    XLSX.writeFile(workbook, 'WeBlue - Dados Urnas.xlsx');
  } catch (error) {
    console.error('Erro ao exportar CSV:', error);
  }
};


export const FileHelper = {
  exportToCsv,
}