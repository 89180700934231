import Icon from "@mui/material/Icon";
import Dashboard from "layouts/dashboard";
import ListarClientes from "layouts/listarClientes";

export const RoutesPaths = {
  dashboard: "/dashboard",
  cadastroDistribuidora: "/cadastroDistribuidora",
  listarDistribuidoras: "/listarDistribuidoras",
  cadastro: "/cadastro"
}

export const Routers = [
  {
    type: "collapse",
    name: "Início",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: RoutesPaths.dashboard,
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Distribuidoras",
    key: "registerList",
    icon: <Icon fontSize="small">person</Icon>,
    route: RoutesPaths.listarDistribuidoras,
    component: <ListarClientes />,
    onlyAdm: true
  },
  {
    type: "collapse",
    name: "Sair",
    key: "logout",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/auth/login",
  },
];
