import Link from "@mui/material/Link";
import MDTypography from "components/MDTypography";

function FooterText({light}) {
  return (
  <>
    &copy; {new Date().getFullYear()} feito por
    <Link href="https://sales.ccxsolucoes.com.br/" target="_blank">
      <MDTypography variant="button" fontWeight="medium" color={light ? "white" : "dark"}>
        &nbsp;CCX Soluções&nbsp;
      </MDTypography>
    </Link>
  </>
    
  );
}

export default FooterText;
