import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DataTable from "components/Tables/DataTable";

import clientsData from "./data/tableData";
import MDButton from "components/MDButton";
import { FileHelper } from "helpers/file";

function ListarPedidosAdm() {
  const { rows, fullReturn } = clientsData();

  const columns = [
    { Header: "CNPJ", accessor: "cnpj",width: "10%", align: "left" },
    { Header: "Nome", accessor: "razao", align: "left" },
    { Header: "Nº Telas na urna", accessor: "urna", width: "30%", align: "center" },
    { Header: "Nº Telas WeFix/ Wk / Nn", accessor: "telaswefix", width: "40%",align: "center" }    
  ]

  const onClickExport = async () =>{
    await FileHelper.exportToCsv(fullReturn);
  }

  return (<>
    <div style={{
      display: 'flex',
      justifyContent: 'flex-end'
    }}>
      <MDBox mt={0} mb={1} >
        <MDButton variant="outlined" color="info" type="button" onClick={onClickExport}>
          Exportar dados
        </MDButton>
      </MDBox>
    </div>
    <MDBox pt={3} pb={3} height="78vh">
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Registro de telas
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <DataTable
                table={{ columns, rows }}
                isSorted
                entriesPerPage
                showTotalEntries={false}
                noEndBorder
              />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  </>);
}

export default ListarPedidosAdm;
