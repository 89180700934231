import { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow
} from "examples/Navbars/DashboardNavbar/styles";
import logoImage from "assets/logo_wefix_blue.png";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav
} from "context";
import MDButton from "components/MDButton";
import { Icon } from "@mui/material";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { transparentNavbar, fixedNavbar, darkMode, miniSidenav } = controller;
  
  const isMobile = window.innerWidth < 1024;

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    window.addEventListener("scroll", handleTransparentNavbar);

    handleTransparentNavbar();

    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMenu = () =>{
    setMiniSidenav(dispatch, !miniSidenav)
  }

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <div style={{display: 'flex'}}>
        <Toolbar sx={(theme) => navbarContainer(theme)}>
          <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
            {isMobile &&
              <MDBox mr={3}>
                <MDButton
                  variant="gradient"
                  color="info"
                  type="button"
                  onClick={handleMenu}
                >
                  <Icon fontSize="small">menu</Icon>
                </MDButton>
              </MDBox>
            }
          </MDBox>
        </Toolbar>
        <MDBox
          component="img"
          src={logoImage}
          alt="logo"
          position="relative"
          zIndex={1}
          width="100%"
          maxWidth="200px"
        />
      </div>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
