import React from 'react';
import { Modal as DefaultModal } from 'react-bootstrap';

function Modal({
  title,
  onClose,
  show,
  footer,
  children
}) {
  return (
    <>
      <DefaultModal show={show} onHide={onClose} centered>
        <DefaultModal.Header closeButton>
          <DefaultModal.Title>{title}</DefaultModal.Title>
        </DefaultModal.Header>
        <DefaultModal.Body>
          {children}
        </DefaultModal.Body>
        <DefaultModal.Footer>
          {footer}
        </DefaultModal.Footer>
      </DefaultModal>
    </>
  );
}

export default Modal;