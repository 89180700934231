// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "components/Layouts/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "components/FooterPages";
import { AuthContext } from "context";
import { useContext } from "react";
import ListarPedidos from "layouts/pedidos/listar";
import ListarPedidosAdm from "layouts/listarPedidos/listar";

function Dashboard() {
  const authContext = useContext(AuthContext);
  const { profile } = authContext;

  const isAdmin = profile.admin || false;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} height={'83vh'}>
      {(profile && !isAdmin) ?
        <ListarPedidos />
      :
        <ListarPedidosAdm />
      }
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
