
import BasicLayoutLanding from "components/Layouts/BasicLayout";
import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";

import bgImage from "assets/bg_login.png";
import bgFrame from "assets/iphoneFrame.png";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { salvarTelaNaUrna } from "services/clientes";
import { Icon, IconButton } from "@mui/material";
import { useParams } from "react-router-dom";
import { obterDadosCliente } from "services/clientes";
import { StringHelper } from "helpers/string";


const INITIAL_STATE = {
  inputs: {
    nome: "",
    telefone: "",
    telas:[
      { qtd: "", modelo: "" }
    ]
  },
  errors: {
    nomeError: false,
    telefoneError: false,
    telasError: false,
    error: false,
    errorText: "",
  },
  distribuidora:{
    cnpj: "", 
    email: "", 
    nome: "",
    telefone: ""
  }
}

function RegistrarTela() {
  const { uid } = useParams();
  const [inputs, setInputs] = useState({...INITIAL_STATE.inputs});
  const [errors, setErrors] = useState({...INITIAL_STATE.errors});
  const [distribuidora, setDistribuidora] = useState({...INITIAL_STATE.distribuidora});

  if(!uid){
    return <></>
  }

  useEffect(()=>{
    const init = async () => {
      const {cnpj, email, nome, telefone} = await obterDadosCliente(uid)
      setDistribuidora({
        cnpj, email, nome, telefone
      });
    }    
    init();
  },[]);

  const changeHandler = (e, index) => {
    if(index >=0){
      const telas = [...inputs.telas];
      const name = e.target.name;
      telas[index][name] = e.target.value;
      setInputs((prev)=>({
        ...prev,
        telas
      }))

    } else {
      setInputs((prev)=>({
        ...prev,
        [e.target.name]: e.target.value,
      }))
    }
  };

  const submitHandler = async (e) => {
    resetErrors();
    e.preventDefault();

    try {
      const isValidForm = validaForm();
      if(!isValidForm){
        return;
      }
      
      const values = {
        ...inputs,
        distribuidora: {
          ...distribuidora,
        },
        telas: inputs.telas.map(i=> (
          {qtd: parseInt(i.qtd) || 0, modelo: i.modelo}
        )),
        uid,
        status: 'PENDENTE',
      };
      
      await salvarTelaNaUrna(values)
      
      clearInputs();

    } catch (err) {
      setErrors({ ...errors, error: true, errorText: err.message });
      console.error(err);
    }
  };

  const clearInputs = () => {
    const clearedInputs = {
      nome: "",
      telefone: "",
      telas:[
        { qtd: "", modelo: "" }
      ]
    };
    setInputs(clearedInputs);
  }

  const resetErrors = () =>{
    setErrors({...INITIAL_STATE.errors});
  }

  const validaForm = () =>{
    const letterFormat = /[a-zA-Z]/; 

    if (inputs.nome.trim().length === 0) {
      setErrors({ ...errors, nomeError: true });
      return false;
    }
    
    if (inputs.telefone.trim().length === 0 || inputs.telefone.trim().length >= 15) {
      setErrors({ ...errors, telefoneError: true });
      return false;
    }

    if (inputs.telefone.trim().match(letterFormat) || !StringHelper.validatePhone(inputs.telefone)) {
      setErrors({ ...errors, telefoneError: true });
      return false;
    }

    const telas = inputs.telas.map((tela)=> {
      if(tela.qtd.trim().match(letterFormat)){
        setErrors({ ...errors, telasError: true });
        return false;
      }
    })
  
    if(telas.some(i => i === false)){
      return false;
    }

    return true
  }

  const addScreen = () => {
    setInputs((prev)=> ({
      ...prev,
      telas: [...prev.telas, {qtd: "", modelo: ""}]
    }))
  }

  const onClickRemove = (index) => {
    const inputsValues = {...inputs};
    inputsValues.telas.splice(index,1);
    setInputs((prev)=> ({
      ...prev,
      telas: inputsValues.telas
    }))
  }

  const onClickReset = () =>{
    clearInputs();
  }

  return (
    <BasicLayoutLanding image={bgImage}>
      <div style={{
        backgroundImage: `url(${bgFrame})`,
        paddingTop: '50px',
        paddingLeft: '25px',
        paddingRight: '25px',
        backgroundRepeat: 'round'
      }}
      >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Cadastro de telas na urna
          </MDTypography>
          <MDBox py={3}>
          <Card style={{maxHeight: '660px', overflowY: 'auto'}}>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form" method="POST" onSubmit={submitHandler}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Nome"
                    variant="standard"
                    fullWidth
                    name="nome"
                    value={inputs.nome}
                    onChange={changeHandler}
                    error={errors.nomeError}
                    inputProps={{
                      autoComplete: "name",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                  {errors.nomeError && (
                    <MDTypography variant="caption" color="error" fontWeight="light">
                      Campo obrigatório
                    </MDTypography>
                  )}
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Telefone"
                    variant="standard"
                    fullWidth
                    name="telefone"
                    value={inputs.telefone}
                    onChange={changeHandler}
                  />
                  {errors.telefoneError && (
                    <MDTypography variant="caption" color="error" fontWeight="light">
                      Campo obrigatório / Dados inválidos
                    </MDTypography>
                  )}
                </MDBox>

                {errors.error && (
                  <MDTypography variant="caption" color="error" fontWeight="light">
                    {errors.errorText}
                  </MDTypography>
                )}

                {inputs.telas.map((tela, index)=>{
                  return(<React.Fragment key={index}>
                    <MDBox display="flex" gap="15px">
                      <MDBox mb={2}>
                        <MDInput
                          type="number"
                          label="Nº de telas"
                          variant="standard"
                          fullWidth
                          name="qtd"
                          value={tela.qtd}
                          onChange={e=>changeHandler(e, index)}

                        />
                        {errors.telasError && (
                          <MDTypography variant="caption" color="error" fontWeight="light">
                            Campo obrigatório / Dados inválidos
                          </MDTypography>
                        )}
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          type="text"
                          label="Modelo"
                          variant="standard"
                          fullWidth
                          name="modelo"
                          value={tela.modelo}
                          onChange={e=>changeHandler(e, index)}
                        />
                      </MDBox>
                      {inputs.telas.length > 1 && 
                        <IconButton size="small" aria-label="close" color="inherit" onClick={()=>onClickRemove(index)}>
                          <Icon fontSize="small">delete</Icon>
                        </IconButton>
                      }
                    </MDBox>
                  </React.Fragment>
                  )
                })}

                <MDBox mt={4} mb={1}>
                  <MDButton variant="gradient" color="info" fullWidth type="button" onClick={addScreen}>
                    Adicionar tela
                  </MDButton>
                </MDBox>
              </MDBox>
            </MDBox>
            <MDBox mt={4} mb={1} pl={2} pr={2} pb={2}>
              <MDBox mb={2}>
                <MDButton variant="gradient" color="info" fullWidth type="button" onClick={submitHandler}>
                  Cadastrar
                </MDButton>
              </MDBox>
              <MDBox>
                <MDButton variant="gradient" color="info" fullWidth type="button" onClick={onClickReset}>
                  Limpar
                </MDButton>
              </MDBox>
            </MDBox>
          </Card>
          </MDBox>
      </div>
    </BasicLayoutLanding>
  );
}

export default RegistrarTela;
''