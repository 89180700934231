
import DashboardLayout from "components/Layouts/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "components/FooterPages";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import { criarUserFirebase } from "services/clientes";
import { salvarInformacoesNoFirestore } from "services/clientes";
import { obterDadosCliente } from "services/clientes";
import { atualizarInformacoesFireStore } from "services/clientes";
import { StringHelper } from "helpers/string";

function CadastroCliente() {
  const { uid } = useParams();
  const navigate = useNavigate();
  const isEdit = !!uid;

  const [inputs, setInputs] = useState({
    nome: "",
    email: "",
    password: "",
    cnpj:"",
    telefone: ""
  });

  const [errors, setErrors] = useState({
    nomeError: false,
    emailError: false,
    passwordError: false,
    cnpjError: false,
    telefoneError: false,
    error: false,
    errorText: "",
  });

  useEffect(()=>{
    async function fecthData(){
      try{
        const res = await obterDadosCliente(uid)
        setInputs((prev)=>({
          ...prev,
          ...res
        }))

      }catch(error){
        console.log(error)
      }
    }
    if(isEdit){
      fecthData()
    }
  },[])

  useEffect(()=>{
    if(!isEdit){
      setInputs({
        nome: "",
        email: "",
        password: "",
        cnpj:"",
        telefone: ""
      });
    }
  },[isEdit])

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setErrors({
      nomeError: false,
      emailError: false,
      passwordError: false,
      error: false,
      errorText: "",
    });

    console.log(StringHelper.validateCNPJ(inputs.cnpj))

    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (inputs.nome.trim().length === 0) {
      setErrors({ ...errors, nomeError: true });
      return;
    }

    if (inputs.email.trim().length === 0 || !inputs.email.trim().match(mailFormat)) {
      setErrors({ ...errors, emailError: true });
      return;
    }

    if (inputs.email.trim().length === 0) {
      setErrors({ ...errors, emailError: true });
      return;
    }

    if (inputs.password.trim().length < 7) {
      setErrors({ ...errors, passwordError: true });
      return;
    }

    if (inputs.cnpj.trim().length === 0 || !StringHelper.validateCNPJ(inputs.cnpj)) {
      setErrors({ ...errors, cnpjError: true });
      return;
    }
    

    if (inputs.telefone.trim().length === 0 || !StringHelper.validatePhone(inputs.telefone)) {
      setErrors({ ...errors, telefoneError: true });
      return;
    }

    try {
    
      const user = !isEdit ? await criarUserFirebase(inputs.email, inputs.password) : null;
      
      const values = {
        ...inputs,
        uid: isEdit ? uid : user.uid,
        status: 'ACTIVE'
      };

      if(isEdit){
        await atualizarInformacoesFireStore({...values, password: '#secret#'})
      } else {
        await salvarInformacoesNoFirestore(values)
      }


      setInputs({
        nome: "",
        email: "",
        password: "",
        cnpj:"",
        telefone: "",
      });

      setErrors({
        nomeError: false,
        emailError: false,
        passwordError: false,
        error: false,
        errorText: "",
      });
      
      navigate('/listarDistribuidoras');

    } catch (err) {
      setErrors({ 
        ...errors, 
        error: true, 
        errorText: err.message 
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
      <Card>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" method="POST" onSubmit={submitHandler}>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Razão Social"
                variant="standard"
                fullWidth
                name="nome"
                value={inputs.nome}
                onChange={changeHandler}
                error={errors.nomeError}
                inputProps={{
                  autoComplete: "name",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
              {errors.nomeError && (
                <MDTypography variant="caption" color="error" fontWeight="light">
                  Campo obrigatório
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                value={inputs.email}
                name="email"
                onChange={changeHandler}
                error={errors.emailError}
                disabled={isEdit}
                inputProps={{
                  autoComplete: "email",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
              {errors.emailError && (
                <MDTypography variant="caption" color="error" fontWeight="light">
                  Email precisa ser válido
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label={isEdit? "Senha ocultada por segurança " :"Senha"}
                variant="standard"
                fullWidth
                name="password"
                value={isEdit ? '' : inputs.password}
                onChange={changeHandler}
                error={errors.passwordError}
                disabled={isEdit}
              />
              {errors.passwordError && (
                <MDTypography variant="caption" color="error" fontWeight="light">
                  A senha precisa ter pelo menos 7 digitos
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="CNPJ"
                variant="standard"
                fullWidth
                name="cnpj"
                value={inputs.cnpj}
                onChange={changeHandler}
                disabled={isEdit}
              />
              {errors.cnpjError && (
                <MDTypography variant="caption" color="error" fontWeight="light">
                  {inputs.cnpj.length > 0 ?
                    'Dados inválidos'
                  :
                    'Campo obrigatório'
                  }
                </MDTypography>
              )}
            </MDBox>

            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Telefone"
                variant="standard"
                fullWidth
                name="telefone"
                value={inputs.telefone}
                onChange={changeHandler}
              />
              {errors.telefoneError && (
                <MDTypography variant="caption" color="error" fontWeight="light">
                  {inputs.telefone.length > 0 ?
                    'Dados inválidos'
                  :
                    'Campo obrigatório'
                  }
                </MDTypography>
              )}
            </MDBox>

            {errors.error && (
              <MDTypography variant="caption" color="error" fontWeight="light">
                {errors.errorText}
              </MDTypography>
            )}
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                {isEdit ? 'Salvar' : 'Cadastrar' }
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CadastroCliente;
