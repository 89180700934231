import { useContext, useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayoutLanding from "components/Layouts/BasicLayout";

// Images
import bgImage from "assets/bg_login.png";

import { AuthContext } from "context";

import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { firebaseApp } from "services/firebase";
import { obterDadosCliente } from "services/clientes";
import { obterDadosAdmin } from "services/clientes";
import { enviarEmailResetSenha } from "services/clientes";

const auth = getAuth(firebaseApp)

function Login() {
  const authContext = useContext(AuthContext);
  const [credentialsErros, setCredentialsError] = useState(null);
  const [esqueceuSenha, setEsqueceuSenha] = useState(false);
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    emailError: false,
    passwordError: false,
  });

  useEffect(()=>{
    const init = ()=>{
      localStorage.removeItem("token");
      localStorage.removeItem("profile");
    }

    init()
  },[])

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    setCredentialsError(null)
    e.preventDefault();

    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (inputs.email.trim().length === 0 || !inputs.email.trim().match(mailFormat)) {
      setErrors({ ...errors, emailError: true });
      return;
    }

    if (inputs.password.trim().length < 6) {
      setErrors({ ...errors, passwordError: true });
      return;
    }

    try {
      const res = await signInWithEmailAndPassword(auth, inputs.email, inputs.password);
      let profile = await obterDadosCliente(res.user.uid);
      if(!profile){
        profile = await obterDadosAdmin(res.user.uid)
        profile.admin = true;
      }
      authContext.updateProfile(profile);
      authContext.login(res.user.accessToken || null);
    } catch (res) {
      setCredentialsError("Erro, verifique suas credenciais e tente novamente!")
    }

    return () => {
      setInputs({
        email: "",
        password: "",
      });

      setErrors({
        emailError: false,
        passwordError: false,
      });
    };
  };

  const ativarModoEsqueceuSenha = () =>{
    setEsqueceuSenha(true);
    setErrors({
      emailError: false,
      passwordError: false,
    });
  }

  const enviarEmailRedefinirSenha = async() =>{
    try{
      alert("Instruções para reset de senha enviados por e-mail");
      await enviarEmailResetSenha(inputs.email)
      setEsqueceuSenha(false);
      setErrors({
        emailError: false,
        passwordError: false,
      });
      setInputs({
        email: "",
        password: "",
      });
    } catch(error){
      console.log(error)
    }
  }

  return (
    <BasicLayoutLanding image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Acesso ao sistema
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" method="POST" onSubmit={submitHandler}>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                value={inputs.email}
                name="email"
                onChange={changeHandler}
                error={errors.emailError}
              />
            </MDBox>
            {!esqueceuSenha && 
              <>
                <MDBox mb={2}>
                  <MDInput
                    type="password"
                    label="Senha"
                    fullWidth
                    name="password"
                    value={inputs.password}
                    onChange={changeHandler}
                    error={errors.passwordError}
                  />
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton variant="gradient" color="info" fullWidth type="submit">
                    ACESSAR
                  </MDButton>
                </MDBox>
                <MDBox>
                  <MDTypography onClick={()=>ativarModoEsqueceuSenha()} variant="button" fontWeight="medium" style={{cursor: 'pointer'}}>
                    Esqueceu senha ?
                  </MDTypography>
                </MDBox>
              </>
            }
            {esqueceuSenha &&
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="info" fullWidth type="button" onClick={()=>enviarEmailRedefinirSenha()}>
                  ENVIAR EMAIL
                </MDButton>
              </MDBox>
            }
            {credentialsErros && (
              <MDTypography variant="caption" color="error" fontWeight="light">
                {credentialsErros}
              </MDTypography>
            )}
            
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayoutLanding>
  );
}

export default Login;
