import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import { useEffect, useState } from "react";
import { listarTodosClientes } from "services/clientes";
import { Icon, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { excluirClienteUsuario } from "services/clientes";
import MDSnackbar from "components/MDSnackbar";
import Modal from "components/Modal";
import MDButton from "components/MDButton";

export default function data() {
  const navigate = useNavigate();
  const [values, setValues] = useState([]);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const closeSuccessSB = () => setAlertSuccess(false);
  const [alertError, setAlertError] = useState(false);
  const closeErrorSB = () => setAlertError(false);
  const [showModal, setShowModal] = useState(false);
  const [valuesToDelete, setValueToDelete] = useState({});

  const [alertSuccessCheck, setAlertSuccessCheck] = useState(false);
  const closeSuccessCheck = () => setAlertSuccessCheck(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);


  const Author = ({ name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const ItemUrl = ({ url }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {url}
      </MDTypography>
    </MDBox>
  );

  useEffect(()=>{
    async function fecthData () {
      try{
        const body = await listarTodosClientes();
        const response = body ? body.map((item) => ({
          author: { name: item.nome, email: item.email },
          function: { title: item.cnpj, description: item.url },
          status: { online: item.status.toUpperCase() === 'ACTIVE' },
          fullReturn: {
            ...item,
            url: `${window.location.origin}/acesso/${item.uid}`
          }
        })) : []
        setValues(response)
      } catch(error){
        console.log(error)
      }
    }
    fecthData();
  },[])

  const onClickEdit = (item) => {
    navigate(`/cadastroDistribuidora/${item.fullReturn.uid}`);
  }

  const doApproveDelete = async () => {
    try{
      await excluirClienteUsuario(valuesToDelete.fullReturn.uid)
      setValues(values.filter((i) => i.fullReturn.uid !== valuesToDelete.fullReturn.uid))
      setAlertSuccess(true);
      handleClose();
      setValueToDelete({});
    } catch(error){
      console.log(error)
      setAlertError(true)
    }
  }

  const onClickDelete = async (item) => {
    handleShow();
    setValueToDelete(item);
  }

  const renderError = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Erro ao remover cliente"
      dateTime="Agora"
      open={alertError}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  )

  const renderSuccess = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Cliente removido com sucesso"
      dateTime="Agora"
      open={alertSuccess}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderSuccessCheck = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Acesso do cliente liberado"
      dateTime="Agora"
      open={alertSuccessCheck}
      onClose={closeSuccessCheck}
      close={closeSuccessCheck}
      bgWhite
    />
  )


  return {
    rows: values.map((item)=>({
      author: <Job title={item.function.title} description={item.function.description} />,
      function: <Author name={item.author.name} email={item.author.email} />,
      status: 
        <MDBox ml={-1}>
          <MDBadge 
            badgeContent={item.status.online ? "ativo" : "inativo"} 
            color={item.status.online ? "success" : "dark"} 
            variant="gradient" size="sm" 
          />
        </MDBox>
      ,
      contract: <ItemUrl url={item.fullReturn.url} />,
      action:
      <>
        <IconButton size="small" aria-label="close" color="inherit" onClick={()=>onClickEdit(item)}>
          <Icon fontSize="small">edit</Icon>
        </IconButton>
        <IconButton size="small" aria-label="close" color="inherit" onClick={()=>onClickDelete(item)}>
          <Icon fontSize="small">delete</Icon>
        </IconButton>
        {renderSuccess}
        {renderError}
        {renderSuccessCheck}

        <Modal
          title='Excluir distribuidora'
          onClose={handleClose}
          show={showModal}
          footer={
            <>
              <MDBox mt={0} mb={1} >
                <MDButton variant="gradient" color="error" type="button" onClick={()=>doApproveDelete()}>
                  Excluir
                </MDButton>
              </MDBox>
            </>
          }
        >
          <>
            <MDBox mb={2}>
              <MDBox lineHeight={3} textAlign="left">
                <MDTypography variant="h6" color="text">
                  Deseja excluir o cadastro/acesso da distribuidora {valuesToDelete?.fullReturn?.email || ''} na plataforma ?
                </MDTypography>
              </MDBox>
            </MDBox>
          </>
        </Modal>

      </>
    }))
  };
}
