// Função para validar CNPJ
function validateCNPJ(cnpj) {
  if (!cnpj) return false;

  // Remove caracteres não numéricos
  cnpj = cnpj.replace(/[\D]/g, "");

  // Verifica se tem 14 dígitos
  if (cnpj.length !== 14) return false;

  // Elimina CNPJs com dígitos repetidos
  if (/^(\d)\1+$/.test(cnpj)) return false;

  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }

  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== parseInt(digitos.charAt(0))) return false;

  tamanho++;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }

  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  return resultado === parseInt(digitos.charAt(1));
}

// Função para validar telefone
function validatePhone(phone) {
  if (!phone) return false;

  // Remove caracteres não numéricos
  phone = phone.replace(/[\D]/g, "");

  // Verifica se tem entre 10 e 11 dígitos (DDD + número)
  if (phone.length < 10 || phone.length > 11) return false;

  // Verifica se o DDD é válido (01 a 99)
  const ddd = parseInt(phone.substring(0, 2), 10);
  if (ddd < 11 || ddd > 99) return false;

  // Verifica se o número começa com dígitos válidos
  const firstDigit = phone.length === 11 ? phone[2] : phone[0];
  if (firstDigit !== '9' && phone.length === 11) return false;

  return true;
}


export const StringHelper = {
  validateCNPJ,
  validatePhone
}