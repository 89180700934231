// Material Dashboard 2 React example components
import DashboardLayout from "components/Layouts/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "components/FooterPages";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { obterDadosPedido } from "services/clientes";
import { Icon, IconButton } from "@mui/material";
import { atualizarPedido } from "services/clientes";
import { AuthContext } from "context";
import { salvarTelaNaUrna } from "services/clientes";
import { StringHelper } from "helpers/string";

function CadastroPedido() {
  const authContext = useContext(AuthContext);
  const { profile } = authContext;
  const { uid } = useParams();
  const navigate = useNavigate();
  const isEdit = !!uid;

  const [inputs, setInputs] = useState({
    nome: "",
    telefone: "",
    telas:[
      { qtd: "", modelo: "" }
    ]
  });

  const [errors, setErrors] = useState({
    nomeError: false,
    telefoneError: false,
    telasError: false,
    error: false,
    errorText: "",
  });

  useEffect(()=>{
    async function fecthData(){
      try{
        const res = await obterDadosPedido(uid)
        setInputs((prev)=>({
          ...prev,
          ...res
        }))

      }catch(error){
        console.log(error)
      }
    }
    if(isEdit){
      fecthData()
    }
  },[])

  useEffect(()=>{
    if(!isEdit){
      setInputs({
        nome: "",
        telefone: "",
        telas:[
          { qtd: "", modelo: "" }
        ]
      });
    }
  },[isEdit])

  const changeHandler = (e, index) => {
    if(index >=0){
      const telas = [...inputs.telas];
      const name = e.target.name;
      telas[index][name] = e.target.value;
      setInputs((prev)=>({
        ...prev,
        telas
      }))

    } else {
      setInputs((prev)=>({
        ...prev,
        [e.target.name]: e.target.value,
      }))
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    
    try {
      const isValidForm = validaForm();
      if(!isValidForm){
        return;
      }
      
      if(isEdit){
        await atualizarPedido({
          ...inputs,
          id: uid,
          status: 'PENDENTE',
          qtdTelasWeFix: 0
        })
      } else {
        const values = {
          distribuidora: { 
            cnpj: profile.cnpj, 
            email: profile.email, 
            nome: profile.nome, 
            telefone: profile.telefone, 
          },
          status: 'PENDENTE',
          uid: profile.uid,
          telas: inputs.telas.map(i=> (
            {qtd: parseInt(i.qtd) || 0, modelo: i.modelo}
          )),
          ...inputs
        }
        await salvarTelaNaUrna(values)
      }


      setInputs({
        nome: "",
        telefone: "",
        telas:[
          { qtd: "", modelo: "" }
        ]
      });

      setErrors({
        nomeError: false,
        telefoneError: false,
        telasError: false,
        error: false,
        errorText: "",
      });
      
      navigate('/dashboard');

    } catch (err) {
      setErrors({ 
        ...errors, 
        error: true, 
        errorText: err.message 
      });
    }
  };

  const validaForm = () =>{
    const letterFormat = /[a-zA-Z]/; 

    if (inputs.nome.trim().length === 0) {
      setErrors({ ...errors, nomeError: true });
      return false;
    }
    
    if (inputs.telefone.trim().length === 0 || inputs.telefone.trim().length >= 15) {
      setErrors({ ...errors, telefoneError: true });
      return false;
    }

    if (inputs.telefone.trim().match(letterFormat) || !StringHelper.validatePhone(inputs.telefone)) {
      setErrors({ ...errors, telefoneError: true });
      return false;
    }

    const telas = inputs.telas.map((tela)=> {
      const type = typeof tela.qtd;
      if(type === "string" && tela.qtd.trim().match(letterFormat)){
        setErrors({ ...errors, telasError: true });
        return false;
      }
    })
  
    if(telas.some(i => i === false)){
      return false;
    }

    return true
  }

  const addScreen = () => {
    setInputs((prev)=> ({
      ...prev,
      telas: [...prev.telas, {qtd: "", modelo: ""}]
    }))
  }

  const onClickRemove = (index) => {
    const inputsValues = {...inputs};
    inputsValues.telas.splice(index,1);
    setInputs((prev)=> ({
      ...prev,
      telas: inputsValues.telas
    }))
  }  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
      <Card>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" method="POST" onSubmit={submitHandler}>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Nome"
                variant="standard"
                fullWidth
                name="nome"
                value={inputs.nome}
                onChange={changeHandler}
                error={errors.nomeError}
                inputProps={{
                  autoComplete: "name",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
              {errors.nomeError && (
                <MDTypography variant="caption" color="error" fontWeight="light">
                  Campo obrigatório
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Telefone"
                variant="standard"
                fullWidth
                name="telefone"
                value={inputs.telefone}
                onChange={changeHandler}
              />
              {errors.telefoneError && (
                <MDTypography variant="caption" color="error" fontWeight="light">
                  Campo obrigatório / Dados inválidos
                </MDTypography>
              )}
            </MDBox>

            {errors.error && (
              <MDTypography variant="caption" color="error" fontWeight="light">
                {errors.errorText}
              </MDTypography>
            )}

            {inputs?.telas?.map((tela, index)=>{
              return(<React.Fragment key={index}>
                <MDBox display="flex" gap="15px">
                  <MDBox mb={2} style={{width: '50%'}}>
                    <MDInput
                      type="number"
                      label="Nº de telas"
                      variant="standard"
                      fullWidth
                      name="qtd"
                      value={tela.qtd}
                      onChange={e=>changeHandler(e, index)}

                    />
                    {errors.telasError && (
                      <MDTypography variant="caption" color="error" fontWeight="light">
                        Campo obrigatório / Dados inválidos
                      </MDTypography>
                    )}
                  </MDBox>
                  <MDBox mb={2} style={{width: '50%'}}>
                    <MDInput
                      type="text"
                      label="Modelo"
                      variant="standard"
                      fullWidth
                      name="modelo"
                      value={tela.modelo}
                      onChange={e=>changeHandler(e, index)}
                    />
                  </MDBox>
                  {inputs.telas.length > 1 && 
                    <IconButton size="small" aria-label="close" color="inherit" onClick={()=>onClickRemove(index)}>
                      <Icon fontSize="small">delete</Icon>
                    </IconButton>
                  }
                </MDBox>
              </React.Fragment>
              )
            })}

            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="button" onClick={addScreen}>
                Adicionar tela
              </MDButton>
            </MDBox>
            <MDBox display="flex" gap="15px">
              <MDBox mt={4} mb={1} style={{width: '50%'}}>
                <MDButton variant="outlined" color="info" fullWidth type="button" onClick={()=>navigate('/dashboard')}>
                  Voltar
                </MDButton>
              </MDBox>
              <MDBox mt={4} mb={1} style={{width: '50%'}}>
                <MDButton variant="gradient" color="info" fullWidth type="submit">
                  {isEdit ? 'Salvar' : 'Cadastrar' }
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CadastroPedido;
