import { useState, useEffect, useContext } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

// react-router components
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 React example components
import Sidenav from "components/Sidenav";
import Configurator from "components/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 React routes
import { Routers, RoutesPaths } from "routers/routes";
import ProtectedRoute from "routers/ProtectedRoute";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";

import Login from "layouts/login";
import { AuthContext } from "context";
import Dashboard from "layouts/dashboard";
import CadastroCliente from "layouts/cadastroCliente";
import ListarClientes from "layouts/listarClientes";
import RegistrarTela from "layouts/registrarTela";
import CadastroPedido from "layouts/pedidos/cadastro";

export default function App() {
  const authContext = useContext(AuthContext);

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route && route.type !== "auth") {
        return (
          <Route
            exact
            path={route.route}
            element={
              <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                {route.component}
              </ProtectedRoute>
            }
            key={route.key}
          />
        );
      }
      return null;
    });


  return (
    <>
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName=""
              routes={Routers}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          <Route path="/" exact element={<IndexPage />} />
          <Route path="/auth/login" element={<Login />} />
          <Route path={RoutesPaths.dashboard} element={<PrivateRoute component={<Dashboard />} />} /> 
          <Route path="/cadastroDistribuidora/:uid?" element={<PrivateRoute component={<CadastroCliente />} />} />
          <Route path="/cadastroPedidos/:uid?" element={<PrivateRoute component={<CadastroPedido />} />} />
          <Route path={RoutesPaths.listarDistribuidoras} element={<PrivateRoute component={<ListarClientes />} />} />
          <Route path="/acesso/:uid?" element={<RegistrarTela />} />
        </Routes>
      </ThemeProvider>
    </>
  );
}

const IndexPage = ({ }) =>{
  const navigate = useNavigate();
  return navigate("/dashboard");
}


const PrivateRoute = ({ component }) => {
  const authContext = useContext(AuthContext);

  if (!authContext.isAuthenticated) {
    return authContext.logout();
  }

  return component;
};
